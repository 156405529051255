import * as actionTypes from "../actions/types";
import { updateObject } from "../../helpers/common/utility";

const initialState: {
  kamar: {
    kamar: string;
    idruang: number;
    nmruang: string;
    idkelas: number;
    nmkelas: string;
    jml_bed: number;
    jk: string;
    infeksi: number;
    jml_kosong: number;
  }[];
} = {
  kamar: [],
};
const setKamar = (state: any, action: any) => {
  return updateObject(state, {
    kamar: action.data,
  });
};
const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_KAMAR_LIST_DASHBOARD:
      return setKamar(state, action);
    default:
      return state;
  }
};

export default reducer;
