import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
const DashboardPage = React.lazy(
  () => import("./pages/dashboard/DashboardPage")
);
// const KamarPage = React.lazy(() => import("./pages/dashboard/KamarPage"));
const KamarDashboardPage = React.lazy(() => import("./pages/dashboard/KamarDashboardPage"));

type Props = {};

type State = {};

export class index extends Component<Props, State> {
  state = {};

  render() {
    return (
      <Routes>
        {/* <Route path="/dashboard" element={<DashboardPage />} /> */}
        {/* <Route path="/dashboard" element={<DashboardPage />} /> */}
        <Route path="/" element={<DashboardPage />} />
        <Route path="/kamar" element={<KamarDashboardPage />} />
        {/* <Route path="/kamar-dashboard" element={<KamarDashboardPage />} /> */}
      </Routes>
    );
  }
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(index);
