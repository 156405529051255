import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { APP_ENVIRONMENT } from "../helpers/common/config";
import rootReducer from "./reducers";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const initState = {};
const middleware = [thunk];
const composeEnhancers =
  (APP_ENVIRONMENT === "development" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;
const store = createStore(
  rootReducer,
  initState,
  /* preloadedState, */ composeEnhancers(applyMiddleware(...middleware))
);

export default store;
