import React, { Suspense } from "react";
import Backdrop from "@mui/material/Backdrop";
// import logo from "./logo.svg";
import { Box, CircularProgress } from "@mui/material";
import { ThemeProvider, makeStyles } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Routes from "./Routes";
import theme from "./theme";
import { connect } from "react-redux";
import { PRI_600 } from "./theme/colors";
import Lottie from "react-lottie-player";
import CatLottie from "./assets/lotties/cat-black.json";
function App({ stateLoading }: { stateLoading: boolean }) {
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    let tmout: any = null;
    // const tmout = setTimeout(() => {
    //   if (stateLoading) {
    //     setOpen(true);
    //   } else {
    //     setOpen(false);
    //     clearTimeout(tmout);
    //   }
    // }, 5000);
    if (stateLoading) {
      setOpen(true);
      if (tmout) {
        return clearTimeout(tmout);
      }
    } else {
      tmout = setTimeout(() => {
        return setOpen(false);
      }, 3000);
    }
    return () => {
      if (tmout) {
        clearTimeout(tmout);
      }
    };
  }, [stateLoading]);
  // React.useEffect(() => {
  //   requestPermission();

  //   return () => {};
  // }, []);
  // onMessageListener()
  //   .then((data) => {
  //     console.log("awwww");
  //     console.log(data);
  //   })
  //   .catch((err) => console.log("awww", err));
  return (
    <ThemeProvider theme={theme}>
      <Box
        position="fixed"
        right={0}
        bottom={0}
        zIndex={100}
        display={open ? "block" : "none"}
      >
        <Lottie
          animationData={CatLottie}
          play
          loop={true}
          style={{
            height: "24vh",
          }}
        />
      </Box>
      <BrowserRouter
      // basename="/web"
      >
        <Suspense
          fallback={
            <span
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                margin: "-25px 0 0 -25px",
              }}
            >
              <CircularProgress
              // sx={{
              //   color: PRI_600 + "!important",
              // }}
              />
            </span>
          }
        >
          <Routes />
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
}

const mapStateToProps = (state: any) => {
  return {
    // stateNotif: state.app.notification,
    stateLoading: state.app.loading,
  };
};
const mapDispatchToProps = (dispatch: Function) => {
  return {
    // actResetNotif: () => dispatch(resetNotif()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
