import * as actionTypes from "../actions/types";
import { updateObject } from "../../helpers/common/utility";

const initialState = {
  loading: false,
  error: "",
  bahasa: "indonesia",
  bahasaOpen: false,
};
const setLoading = (state: any, action: any) => {
  return updateObject(state, {
    loading: action.data,
  });
};
const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return setLoading(state, action);
    default:
      return state;
  }
};

export default reducer;
