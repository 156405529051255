import { setError } from "../../redux/actions/app";
import moment from "moment";
export const updateObject = (oldObject: any, updatedProps: any) => {
  return {
    ...oldObject,
    ...updatedProps,
  };
};

export const formatNumber = (num: number) => {
  return "IDR " + num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const stripMR = (val: string) => {
  let arr = val.split("");
  let text = "";
  arr.map((item, index) => {
    if (index > 0 && index % 2 === 0) {
      text += "-";
    }
    return (text += item);
  });
  return text;
};

export const parseToString = (val: number) => {
  return Number.isInteger(val) ? val.toString() : val;
};

export const toBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const isJSON = (payload: any) => {
  try {
    let o = JSON.parse(payload);
    if (o && typeof o === "object") {
      return true;
    }
  } catch (error) {
    return false;
  }
};

export const errorHandler = (dispatch: Function, err: any) => {
  if (err.toString().includes("Network Error")) {
    dispatch(setError("connection"));
  } else if (err.response.status === 401) {
    dispatch(setError("not-authenticated"));
  } else {
    dispatch(setError("system"));
  }
};

export const getDiffDay = (date: string) => {
  let now = moment(moment().format("YYYY-MM-DD"));
  let end = moment(date, "YYYY-MM-DD");
  let duration = moment.duration(now.diff(end));
  return duration.asDays();
};

export const getDaysBetweenDates = (startDate: any, endDate: any) => {
  startDate = moment(startDate);
  endDate = moment(endDate);
  let now = startDate.clone();
  let dates = [];

  while (now.isSameOrBefore(endDate)) {
    let month = now.format("MMMM");
    // if (dates.length > 0) {
    //   console.log(Object.keys(dates[0])[0])

    // }
    if (now.day() !== 0) {
      let found = null;
      if (dates.length > 0) {
        found = dates.find((e) => Object.keys(e)[0] === month);
      }
      if (found) {
        let key: any = Object.keys(found)[0];
        found[key].push({
          date: now.format("YYYY-MM-DD"),
          month: month,
          day: now.format("ddd"),
          day_number: now.format("DD"),
        });
      } else {
        dates.push({
          [month]: [
            {
              date: now.format("YYYY-MM-DD"),
              month: month,
              day: now.format("ddd"),
              day_number: now.format("DD"),
            },
          ],
        });
      }

      // dates.push({
      //   date: now.format("YYYY-MM-DD"),
      //   month: month,
      //   day: now.format("ddd"),
      //   day_number: now.format("DD"),
      // });
    }

    now.add(1, "days");
  }

  return dates;
};

export const randomFromArray = (items: Array<any>) => {
  return items[Math.floor(Math.random() * items.length)];
};

export const simplifyNumber = (number: number | string) => {
  let unitlist = ["", "K", "M", "G"];
  if (typeof number === "string") {
    return number;
  }
  let sign = Math.sign(number);
  let unit = 0;

  while (Math.abs(number) >= 1000) {
    unit = unit + 1;
    number = Math.floor(Math.abs(number) / 100) / 10;
  }
  return `${sign * Math.abs(number)} ${unitlist[unit]}`;
};

export const displayType = (value: any, total: number, type: number) => {
  let val = value;
  if (type === 2) {
    val = Math.round((value / total) * 100);
    if (isNaN(val)) {
      val = 0;
    }
    val = val + "%";
  }
  return val;
};
